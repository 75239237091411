import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Scope } from '../../models/scope';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent {

  constructor(private router: Router, private oauthService: AuthService) {

  }

  mode = new UntypedFormControl('over');


  public getRole(): string {
    return this.oauthService.userRole;
  }

  redirect(pagename: string) {
    this.router.navigate(['/' + pagename]);
  }

  logout() {
    this.oauthService.logout();
  }
}

