import { HttpParams } from '@angular/common/http';

export class QueryParamsConstructor {
    static FORBIDDEN_VALUES = [null, undefined, -1, '', ' '];

    static fromObject(obj: object): HttpParams {
        if (!obj) {
            return new HttpParams();
        }

        if (obj instanceof HttpParams) {
            return obj;
        }

        return Object.keys(obj)
            .filter(key => key && !QueryParamsConstructor.FORBIDDEN_VALUES.includes(obj[key]))
            .reduce((acc, curr) => {

                const value = obj[curr];
                if (value instanceof Array) {
                    (value as any[]).forEach(item => item ? acc = acc.append(curr, item.toString()) : null);
                    return acc;
                } else if (value instanceof Object) {
                    return acc.append(curr, QueryParamsConstructor.fromObject(value).toString());
                } else {
                    return acc.append(curr, value);
                }
            }, new HttpParams()
            );
    }

    static fromObjects(...objs: object[]) {
        const merged = objs.reduce((acc, curr) => Object.assign(acc, curr), {});
        return QueryParamsConstructor.fromObject(merged);
    }

    static fromArray(key: string, arr: any[]) {
        if (arr instanceof Array) {
            return arr.reduce((acc: HttpParams, curr) => {
                return acc.append(key, curr);
            }, new HttpParams());
        }
    }
}
