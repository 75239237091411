import { Component, OnInit } from '@angular/core';
import { LicenseService } from '../../services/license/license.service';
import { ActivatedRoute, Router } from '@angular/router';
import { License } from '../../models/license';
import { ProductDto } from '../../models/products';
import { Scope } from '../../models/scope';
import { ScopeService } from '../../services/scope/scope.service';
import { ProductService } from '../../services/product/product.service';
import { ScopeProduct } from '../../models/scopeProduct';
import { ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-license-add',
  templateUrl: './license-add.component.html',
  styleUrls: ['./license-add.component.css']
})
export class LicenseAddComponent implements OnInit {

  public license: License;

  public selectedProduct: ProductDto;
  public productId: string;
  public productArray: ProductDto[] = [];
  public newDomain: string;
  public hidden: boolean = false;
  public id: string;

  public scopeArray: Scope[] = [];
  @ViewChild('submitButton') submitButton: ElementRef;

  public scopesProductArray: ScopeProduct[] = [];

  constructor(
    private productService: ProductService,
    protected scopeService: ScopeService,
    protected licenseService: LicenseService,
    protected route: ActivatedRoute,
    protected router: Router
  ) {

  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.license = new License(this.id);
    this.newDomain = '';
    const date = new Date();
    this.license.expiration = { day: date.getDate(), month: date.getUTCMonth() + 1, year: date.getFullYear() + 1 };
    this.getProducts();
    this.getScopes();
  }

  public removeDomain(domain: string) {
    this.license.domains = this.license.domains.filter(u => u !== domain);
  }

  public click(i: boolean) {
    this.hidden = i;
  }

  public addDomain() {
    if (this.license.domains == null) { return; }
    if (this.newDomain) {
      this.license.domains.push(this.newDomain);
      this.newDomain = '';
    }
  }

  public getProducts() {
    this.productService.getProducts().then(data => {
      this.productArray = data;
      this.productId = this.productArray[0].id;
      this.createScopeArray();
    });
  }

  public getProductId(product: ProductDto) {
    this.productId = product.id;
  }


  public getScopes() {
    this.scopeService.getScopes().then(data => {
      this.scopeArray = data;
      this.scopeArray.forEach(scope => {
        this.scopesProductArray.push({ id: scope.id, name: scope.name, description: scope.description, status: false,
           packageSets: scope.packageSets });
      });
      this.createScopeArray();
    });
  }

  public createScopeArray() {
    const prod = this.productArray.find(i => i.id === this.productId);
    if (prod && prod.scopes) {


      this.scopesProductArray.forEach(scope => {
        scope.status = false;
        prod.scopes.forEach(scopeProduct => {
          if (scope.name === scopeProduct.name) {
            scope.status = true;
          }
        });
      });
    }
  }

  public save() {
    this.submitButton.nativeElement.disabled = true;
    this.license.hidden = this.hidden;
    this.license.expiration.month -= 1;
    this.scopesProductArray.forEach(element => {
      if (element.status === true) {
        this.license.scopes.push(element);
      }
    });

    this.license.productId = this.productId;
    this.license.product = this.productArray.find(c => c.id === this.productId);
    this.licenseService.createLicense(this.license).then((license) => {
      if (license) {
        this.router.navigate(['/licenses', this.id]);
      }
      this.submitButton.nativeElement.disabled = false;
    }).catch(err => {
      this.router.navigate(['products']);
      console.log(err);
      this.submitButton.nativeElement.disabled = false;
    });
  }
}
