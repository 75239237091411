export class PackageResponse {
constructor(id?:string){
  this.id = id;
  this.npm=[];
  this.nuGet=[];
  this.fileRepository=[];
}

  public id: string;
  public name: string;
  public description: string;
  public nuGet: Array<string>;
  public npm: Array<string>;
  public fileRepository: Array<string>;

}


export class PackageDeleteResponse {
  constructor(){
    this.npm=[];
    this.nuGet=[];
    this.fileRepository=[];
  }
  public id: string;
  public name: string;
  public description: string;
  public nuGet: Array<string>;
  public npm: Array<string>;
  public fileRepository: Array<string>;
}
