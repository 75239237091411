import {AfterViewInit, Directive, ElementRef, EventEmitter, Output} from '@angular/core';

@Directive({
  selector: '[appOnEnter]'
})
export class EnterHandlerDirective implements AfterViewInit {
  @Output() onEnter = new EventEmitter<string>();

  constructor(private _el: ElementRef) {
  }

  ngAfterViewInit() {
    this._el.nativeElement.addEventListener('keydown', (event: any) => {
      setTimeout(() => {
        if (event.keyCode === 13) { // Enter
          this.onEnter.emit(event.target.value as string);
        }
      });
    });
  }
}
