import { Injectable } from '@angular/core';
import { DataService } from '../data.service';
import { ProductDto } from '../../models/products';
import { ProductFactory } from '../../factories/product.factory';

@Injectable()
export class ProductService {

    constructor(private dataService: DataService) { }

    /**
    * Get all products
    */
    public getProducts(): Promise<ProductDto[]> {
        return new Promise((resolve, reject) => {
            this.dataService.getProducts().then(product => {
                return product ? resolve(product) : reject();
            });
        });
    }

    /**
     * Get Product by id
     *@param id type string
    */
    public getProduct(id: string): Promise<ProductDto> {
        return new Promise((resolve, reject) => {
            this.dataService.getProduct(id).then(product => {
                return product ? resolve(product) : reject();
            });
        });
    }

    /**
      * Add Product
      * @param product
      */
    public addProduct(product: ProductDto): Promise<ProductDto> {
        return new Promise((resolve, reject) => {
            this.dataService.addProduct(ProductFactory.createAddRequest(product)).then(response => {
                return product ? resolve(product) : reject();
            }).catch(reject);
        });
    }

    /**
    * Update Product
    * @param Product
    */
    public updateProduct(product: ProductDto): Promise<ProductDto> {
        return new Promise((resolve, reject) => {
            this.dataService.updateProduct(product.id, ProductFactory.createUpdateRequest(product)).then(response => {
                return product ? resolve(product) : reject();
            }).catch(reject);
        });
    }

    /**
    * Delete Product
    * @param id
    */
    public deleteProduct(id: string): Promise<ProductDto> {
        return new Promise((resolve, reject) => {
            this.dataService.deleteProduct(id).then(response => {
                if (response) { resolve(ProductFactory.delete(response)); }
            }).catch(reject);
        });
    }
}
