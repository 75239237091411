import { Component, OnInit } from '@angular/core';
import { PackageSets } from '../../models/packagesets';
import { ActivatedRoute } from '@angular/router';
import { PackageService } from '../../services/package/package.service';
import { PackageResponse } from '../../services/package/package.response';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LinkComponent } from '../link/link.component';

@Component({
  selector: 'app-packagesets',
  templateUrl: './packagesets.component.html',
  styleUrls: ['./packagesets.component.css']
})
export class PackagesetsComponent implements OnInit {

  public package: PackageSets[];
  public packageResponse: PackageResponse[];

  constructor(private packageService: PackageService, public dialog: MatDialog, private route: ActivatedRoute) {
    this.package = new PackageSets()[0];
    this.packageResponse = new PackageResponse()[0];
  }

  ngOnInit() {
    console.log(this.packageResponse);
    this.packageService.getPackages().then(data => {
      this.packageResponse = data;
      console.log(data);
    })
  }
  public deleteItem(id: number, name: string) {
    if (confirm("Are you sure to delete scope: " + name + " ?")) {
      const item = this.packageResponse[id];
      this.packageService.deletePackage(item.id).then(c => {
        this.packageResponse.splice(id, 1);
      });
    }
  }

}
