export class Customer {
    /**
     *
     */
    constructor(id?: string) {
        this.id = id;
    }
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    userName: string;
    address: string;
    apiKey: string;
    customerKey: string;
    isActivate: boolean;
    isRejected: boolean;
    kysStatus: KysStatuses;
}

export enum KysStatuses {
    Pending = 'Pending',
    Accepted = 'Accepted',
    Rejected = 'Rejected'
}

export enum KysStatusFlags {
    Accepted = 1,
    Rejected = 2,
    Pending = 4,
    AcceptedAndRejected = Accepted | Rejected,
    AcceptedAndPending = Accepted | Pending,
    RejectedAndPending = Rejected | Pending,
    All = Accepted | Rejected | Pending
}
