import { LicenseResponse } from '../services/license/license.response';
import { License } from '../models/license';
import { AddLicenseRequest } from '../services/license/add.license.request';
import { UpdateLicenseRequest } from '../services/license/update.license.request';


export class LicenseFactory {

    public static create(model: LicenseResponse): License {
        const expiration = new Date(model.expiration);
        return {
            id: model.id,
            scopes: model.scopes,
            product: model.product,
            expiration: {
                year: expiration.getFullYear(),
                month: expiration.getMonth(),
                day: expiration.getDate()
            },
            productId: model.productId,
            domains: model.domains,
            note: model.note,
            hidden: model.hidden
        };
    }


    public static createModels(models: LicenseResponse[]): License[] {
        return models.map(LicenseFactory.create);
    }

    public static createAddRequest(model: License): AddLicenseRequest {
        return {
            scopes: model.scopes,
            productId: model.productId,
            product: model.product,
            expiration: new Date(model.expiration.year, model.expiration.month, model.expiration.day, 0, 0, 0).getTime(),
            domains: model.domains,
            customerId: model.customerId,
            note: model.note,
            hidden: model.hidden
        };
    }

    public static createUpdateRequest(model: License): UpdateLicenseRequest {
        return {
            id: model.id,
            domains: model.domains,
            note: model.note,
            expiration: new Date(model.expiration.year, model.expiration.month, model.expiration.day, 0, 0, 0).getTime(),
            hidden: model.hidden
        };
    }
}
