import { Component, OnInit } from '@angular/core';
import { License } from '../../models/license';
import { LicenseService } from '../../services/license/license.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CustomerService } from '../../services/customer/customer.service';

@Component({
  selector: 'app-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.css']
})
export class LicenseComponent implements OnInit {

  public license: License;
  public newDomain: string;
  public licenseArray: License[] = [];
  public customerId: string;

  constructor(
    protected licenseService: LicenseService,
    protected route: ActivatedRoute,
    protected router: Router,
    private customersService: CustomerService,
  ) {
    this.license = new License();
  }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    this.customerId = id;
    this.customersService.getLicensesByCustomeId(id).then(data => {
      this.licenseArray = data;
    });
  }

  public getLicenses() {
    const id = this.route.snapshot.paramMap.get('id');
    this.customerId = id;
    this.customersService.getLicensesByCustomeId(id).then(data => {
      this.licenseArray = data;
      console.log(this.licenseArray);
    });
  }

  public formatDate(expiration: NgbDateStruct): Date {
    return new Date(expiration.year, expiration.month, expiration.day);
  }

  public formatDomains(license: License): string {
    return license.domains && license.domains.slice(0, 3).toString();
  }

  public navigateTo(path: string[]) {
    this.router.navigate(path);
  }

  public removeLicense(idLicence: number): void {
    if(confirm("Are you sure to delete license?")){
    console.log(idLicence);
    const licenceToRemove = this.licenseArray[idLicence];
    this.licenseService.deleteLicense(licenceToRemove.id).then(c => {
      this.licenseArray.splice(idLicence, 1);
    });
  }
  }
}
