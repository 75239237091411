import {Component, OnInit} from '@angular/core';
import {Customer, KysStatuses, KysStatusFlags} from '../../models/customer';
import {ActivatedRoute} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {CustomerService} from '../../services/customer/customer.service';
import {IdentityService} from '../../services/customer/identity.service';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css'],
  providers: [NgbDropdownConfig]
})
export class CustomersComponent implements OnInit {
  public kysStatusFlag: KysStatusFlags = KysStatusFlags.All;
  public pendingCustomersCount: Number = 0;
  public KysStatuses = KysStatuses;
  public KysStatusFlags = KysStatusFlags;
  public customers: Customer[] = [];
  private _allCustomers: Customer[];

  constructor(private customersService: CustomerService,
              private route: ActivatedRoute,
              public dialog: MatDialog,
              private identity: IdentityService) {
  }

  ngOnInit() {
    this.customersService.getPendingCount().then(response => {
      this.pendingCustomersCount = response;
    });

    this.setupCustomers();
  }

  public setupCustomers() {
    this.customersService.getCustomers(this.kysStatusFlag).then(data => {
      data.forEach((item) => item.kysStatus = this.getKysStatus(item));
      this.customers = data;
      this._allCustomers = data;
    });
  }

  public search(event) {

    const query = event.target.value;
    if (!query.length) {
      this.customers = this._allCustomers;
    } else {
      this.customers = this._allCustomers.filter(i => i.email.includes(query));
    }

  }

  public toggleFlag(status: KysStatusFlags) {
    this.kysStatusFlag = this.kysStatusFlag ^ status;

    this.setupCustomers();
  }

  public isFlagMatches(status: KysStatusFlags): Boolean {
    return (status & this.kysStatusFlag) > 0;
  }

  public activateCustomer(id: number, name: string) {
    if (confirm('Are you sure to activate user: ' + name + '?')) {
      const customerActive = this.customers[id];
      customerActive.isActivate = true;
      this.customersService.isActivateCustomers(customerActive).then(response => {
        if (response) {
          customerActive.isActivate = response.isActivate;
        }
      });
    }
  }

  public deleteItem(id: number, name: string) {
    if (confirm('Are you sure to delete user: ' + name + '?')) {
      const customerToRemove = this.customers[id];
      this.customersService.deleteCustomer(customerToRemove.id).then(c => {
        this.customers.splice(id, 1);
      });
    }
  }

  public acceptCustomer(customer: Customer) {
    this.identity.acceptCustomer(customer.email)
      .then(() => customer.kysStatus = KysStatuses.Accepted);
  }

  public rejectCustomer(customer: Customer) {
    const reason = prompt('Reason to reject user: ', '');
    if (!reason) {
      return;
    }
    this.identity.rejectCustomer(customer.email, reason)
      .then(() => customer.kysStatus = KysStatuses.Rejected);
  }

  public getKysStatus(customer: Customer): KysStatuses {
    if (!customer.isRejected) {
      return customer.isActivate ? KysStatuses.Accepted : KysStatuses.Pending;
    } else {
      return KysStatuses.Rejected;
    }
  }
}
