import { UpdateResponse } from '../services/update/update.responce';
import { Update } from '../models/update';
import { Change } from '../models/change';
import { ChangeDeleteResponce } from '../services/change/change.delete.responce';

export class UpdateFactory {

    /**
     * static create
     * @param model
     */
    public static create(model: UpdateResponse): Update {
        return {
            id: model.id,
            changes: model.changes,
            from: model.from,
            to: model.to,
            isNotified: model.isNotified
        };
    }

    public static deleteChangeResponse(model: Change): ChangeDeleteResponce {
        return {
           id: model.id,
           changes: model.changes,
           version: model.version,
           packageSetId: model.packageSetId,
           product: model.product,
           updateId: model.updateId
        };
    }

}

