// Filtration for data if in server not implementing filter

const enum FilterType {
  Search,
  Contain
}

interface IFilterField {
  value: any;
  type: FilterType;
}

interface IFiltrationData<T> {
  filteredItems: T[];

  filterByValue(key: string, value?: any): void;

  filterByTerm(key: string, value: string): void;

  setItems(items: T[]): void;

  clearFilter(): void;
}

export class FiltrationData<T> implements IFiltrationData<T> {
  public filteredItems: T[];
  private _items: T[];
  private _filteredFields: { [key: string]: IFilterField } = {};

  constructor(items: T[] = []) {
    this._items = items;
    this.filteredItems = items;
  }

  filterByValue(key: string, value: any = null) {
    this._removeFilterField(key);
    if (value !== null) {
      this._filteredFields[key] = {value: value, type: FilterType.Contain};
    }
    this._filterItems();
  }

  filterByTerm(key: string, value: string) {
    this._filteredFields[key] = {value: value, type: FilterType.Search};
    this._filterItems();
  }

  clearFilter(): void {
    this.filteredItems = this._items;
    this._filteredFields = {};
  }

  setItems(items: T[]): void {
    this._items = items;
    this._filterItems();
  }

  private _removeFilterField(key: string) {
    if (this._filteredFields.hasOwnProperty(key)) {
      delete this._filteredFields[key];
    }
  }

  private _filterByValue(key: string, value: any) {
    this.filteredItems = this.filteredItems.filter(i => i[key] === value);
  }

  private _filterByTerm(key: string, value: string) {
    this.filteredItems = this.filteredItems.filter(i => i[key].includes(value));
  }

  private _filterItems(): void {
    this.filteredItems = this._items;
    Object.keys(this._filteredFields).forEach(prop => {
      const field = this._filteredFields[prop];
      field.type === FilterType.Search ? this._filterByTerm(prop, field.value) : this._filterByValue(prop, field.value);
    });
  }
}
