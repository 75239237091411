import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private readonly authService: AuthService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> {
      return new Promise<boolean>((resolve, reject) => {
        this.authService.isLoggedId().then(result => {
          if (!result) {
            this.authService.login();
            this.authService.parseToken(this.authService.getToken());
            resolve(false);
          } else {
            this.authService.parseToken(this.authService.getToken());
            resolve(true);
          }
        });
    });
  }
}
