import {Component, OnInit} from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import {PingLogsService} from '../../services/ping-logs.service';
import {IPingLog} from '../../models/pingLog';
import {FormControl} from '@angular/forms';
import {FiltrationData} from '../../utils/Filtration';

@Component({
  selector: 'app-ping-logs',
  templateUrl: './ping-logs.component.html',
  styleUrls: ['./ping-logs.component.css'],
  providers: [NgbDropdownConfig]
})
export class PingLogsComponent implements OnInit {
  readonly selectAll = 'All';
  searchControl = new FormControl('');
  pingLogsFiltration = new FiltrationData<IPingLog>();

  constructor(private _pingLogsService: PingLogsService) {
  }

  ngOnInit(): void {
    this._pingLogsService.getPingLogs().subscribe(logs => {
      this.pingLogsFiltration.setItems(logs.reverse());
    }, err => {
      console.error(err);
    });
  }

  search() {
    const query = this.searchControl.value;
    this.pingLogsFiltration.filterByTerm('domain', query);
  }

  handleSelectWhiteListed(event) {
    let value = null;
    if (event.value !== this.selectAll) {
      value = event.value;
    }

    this.pingLogsFiltration.filterByValue('isWhiteListed', value);
  }
}
