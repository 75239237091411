import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {IPingLog} from "../models/pingLog";
import {environment} from "../../environments/environment";

@Injectable()
export class PingLogsService {
  private _baseUrl = environment.pingUrl;

  constructor(private _http: HttpClient) {
  }

  public getPingLogs(): Observable<IPingLog[]> {
    const url = `${this._baseUrl}/verify/logs`;
    return this._http.get<IPingLog[]>(url);
  }
}
