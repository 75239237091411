import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CustomersComponent } from './components/customers/customers.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './guards/auth.guard';
import { OAuthModule } from 'angular-oauth2-oidc';
import { CustomerService } from './services/customer/customer.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EnumStatePipePipe } from './pipe/enum.state.pipe';

import { LicenseDetailComponent } from './components/license-detail/license-detail.component';
import { LicenseService } from './services/license/license.service';
import { DataService } from './services/data.service';
import { LicenseEditComponent } from './components/license-edit/license-edit.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LicenseAddComponent } from './components/license-add/license-add.component';
import { CustomerAddComponent } from './components/customer-add/customer-add.component';
import { CustomerEditComponent } from './components/customer-edit/customer-edit.component';
import { MaterialModule } from './modules/material/material.module';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { ProductsComponent } from './components/products/products.component';
import { ProductAddComponent } from './components/product-add/product-add.component';
import { ProductEditComponent } from './components/product-edit/product-edit.component';
import { ProductService } from './services/product/product.service';
import { LinkComponent } from './components/link/link.component';
import { ScopeComponent } from './components/scope/scope.component';
import { ScopeAddComponent } from './components/scope-add/scope-add.component';
import { ScopeEditeComponent } from './components/scope-edite/scope-edite.component';
import { ScopeService } from './services/scope/scope.service';
import { LicenseComponent } from './components/license/license.component';
import { PackagesetsComponent } from './components/packagesets/packagesets.component';
import { PackageService } from './services/package/package.service';
import { AddPackagesetsComponent } from './components/add-packagesets/add-packagesets.component';
import { PackagesetsUpdateComponent } from './components/packagesets-update/packagesets-update.component';
import { PackageManagerAdminComponent } from './components/package-manager-admin/package-manager-admin.component';
import { KeysPipe } from './pipe/keys-pipe.pipe';
import { MasterService } from './services/master/master.service';
import { UpdatesComponentComponent } from './components/updates/updates.component';
import { UpdateService } from './services/update/update.service';
import { IdentityService } from './services/customer/identity.service';
import { ChangesComponent, ChangesModalComponent } from './components/changes/changes.component';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {PingLogsComponent} from './components/ping-logs/ping-logs.component';
import {PingLogsService} from './services/ping-logs.service';
import {EnterHandlerDirective} from './directives/enter-handler.directive';


@NgModule({
    declarations: [
        AppComponent,
        DashboardComponent,
        CustomersComponent,
        LicenseDetailComponent,
        LicenseEditComponent,
        LicenseAddComponent,
        CustomerAddComponent,
        CustomerEditComponent,
        EnumStatePipePipe,
        ProductsComponent,
        ProductAddComponent,
        ProductEditComponent,
        LinkComponent,
        ScopeComponent,
        ScopeAddComponent,
        ScopeEditeComponent,
        LicenseComponent,
        PackagesetsComponent,
        AddPackagesetsComponent,
        PackagesetsUpdateComponent,
        PackageManagerAdminComponent,
        KeysPipe,
        UpdatesComponentComponent,
        ChangesComponent,
        ChangesModalComponent,
        PingLogsComponent,
         EnterHandlerDirective
        // ChangeModalComponent
    ],
    exports: [
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatStepperModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MatNativeDateModule,
        MatDialogModule,
        MaterialModule,
        MatNativeDateModule,
        BrowserModule,
        BrowserAnimationsModule,
        MatTableModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        OAuthModule.forRoot(
        // {
        //   resourceServer: {
        //     allowedUrls: [environment.apiEndpoint],
        //     sendAccessToken: true
        //   }
        // }
        ),
        NgbModule
    ],
    providers: [
        HttpClientModule,
        CustomerService,
        ProductService,
        AuthService,
        AuthGuard,
        DataService,
        LicenseService,
        ScopeService,
        PackageService,
        MasterService,
        UpdateService,
        IdentityService,
        PingLogsService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
