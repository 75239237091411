import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class AuthService {

  public userRole: string;

  constructor(private oauthService: OAuthService) {}

  public parseToken(data: string) {
    if (data) {
      const jwtData = data.split('.')[1];
      const decodedJwtJsonData = window.atob(jwtData);
      const decodedJwtData = JSON.parse(decodedJwtJsonData);
      if (decodedJwtData.role) {
        this.userRole = decodedJwtData.role;
      }
    } else {
      this.userRole = ' ';
    }
  }

  public login(): void {
    this.oauthService.initImplicitFlow(); // l: admin@admin.mail p: administratoR1.
  }

  public getToken(): string {
    return this.oauthService.getAccessToken();
  }

  public logout(): void {
    this.oauthService.logOut();
  }

  public isLoggedId(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const accessToken = this.oauthService.getAccessToken();
      if (accessToken) {
        const expiration = this.oauthService.getAccessTokenExpiration();
        if (expiration && (expiration > Date.now())) {
          return resolve(true);
        }
      } else { return resolve(false); }
    });
  }
}


