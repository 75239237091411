import { Injectable } from '@angular/core';
import { Customer } from '../../models/customer';
import { DataService } from '../data.service';
import { Observable } from 'rxjs';

@Injectable()
export class IdentityService {
    constructor(private dataService: DataService) {
    }

    /**
     * Accept Customer
     * @param email
    */
    public acceptCustomer(email: string): Promise<any> {
        return this.dataService.acceptCustomer(email).toPromise();
    }

    /**
     * Reject Customer
     * @param email
     * @param reason
    */
    public rejectCustomer(email: string, reason: string): Promise<any> {
        return this.dataService.rejectCustomer(email, reason).toPromise();
    }
}
