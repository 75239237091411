import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '../environments/environment';

export const authConfig: AuthConfig = {

  // Url of the Identity Provider
  issuer: environment.authUrl,
  loginUrl: environment.authUrl + '/connect/authorize',

  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin + '/',
  // redirectUri: 'http://localhost:4200/',
  postLogoutRedirectUri: window.location.origin + '/',
   // URL of the SPA to redirect the user after silent refresh
  silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
  responseType: 'id_token token',
  // The SPA's id. The SPA is registerd with this id at the auth-server
  clientId: 'customersManagementOIDC',
  scope: 'openid offline_access profile customers',

  showDebugInformation: true,

  sessionChecksEnabled: true,
  requireHttps: environment.production,
};
