import { Component, OnInit } from '@angular/core';
import { LicenseService } from '../../services/license/license.service';
import { ActivatedRoute, Router } from '@angular/router';
import { License } from '../../models/license';
import { LicenseAddComponent } from '../license-add/license-add.component';
import { FormsModule } from '@angular/forms';
import { ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-license-edit',
  templateUrl: './license-edit.component.html',
  styleUrls: ['./license-edit.component.css']
})
export class LicenseEditComponent implements OnInit {
  @ViewChild('submitButton') submitButton: ElementRef;

  public license: License;
  public newDomain: string;
  public date: Date;
  public strDate: string;
  public hidden: boolean;
  constructor(
    protected licenseService: LicenseService,
    protected route: ActivatedRoute,
    protected router: Router
  ) {
    //  super(licenseService, route, router);

  }

  ngOnInit() {
    this.date = new Date();
    const id = this.route.snapshot.paramMap.get('id');
    this.licenseService.getLicense(id).then(license => {
      this.hidden = license.hidden;
      this.date.setDate(license.expiration.day);
      this.date.setMonth(license.expiration.month);
      this.date.setFullYear(license.expiration.year);
      this.license = license;
      this.strDate = this.date.getFullYear() + "-" + this.date.getMonth() + "-" + this.date.getDate();
      this.license.expiration = { day: this.date.getDate(), month: this.date.getMonth() + 1, year: this.date.getFullYear() };

    }).catch(err => {
      // console.info('Issue with loading license', err);
    });
  }

  public removeDomain(domain: string) {
    this.license.domains = this.license.domains.filter(u => u !== domain);
  }

  public addDomain() {
    if (this.license.domains == null) { return; }
    if (this.newDomain) {
      if (this.license.domains.every(n => n !== this.newDomain)) {
        this.license.domains.push(this.newDomain);
        this.newDomain = '';
      }
    }
  }
  public click(i: boolean) {
    this.hidden = i;
  }
  public save() {
    this.submitButton.nativeElement.disabled = true;
    this.license.hidden = this.hidden;
    this.license.expiration.month -= 1;
    this.licenseService.updateLicense(this.license).then(() => {
      if (this.license) {
        this.router.navigate(['/licenses', this.license.customerId]);
      }
      this.submitButton.nativeElement.disabled = false;

    }).catch(err => {
      console.log(err);
      this.submitButton.nativeElement.disabled = false;

    });
  }

}
