import { Component, OnInit } from '@angular/core';
import { Scope } from '../../models/scope';
import { ScopeService } from '../../services/scope/scope.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-scpoe',
  templateUrl: './scope.component.html',
  styleUrls: ['./scope.component.css']
})
export class ScopeComponent implements OnInit {

  public scopeArray: Scope[] = [];

  constructor(
    protected scopeService: ScopeService,
    protected route: ActivatedRoute,
    protected router: Router
  ) {}

  ngOnInit() {
    this.scopeService.getScopes().then(data => {
      this.scopeArray =  data;
      // console.log(this.scopeArray);
    });
  }

  public deleteItem(id: number,name: string) {
    if(confirm("Are you sure to delete scope: "+name+" ?")){
    const article = this.scopeArray[id];
    this.scopeService.deleteArticle(article.id).then(c => {
      this.scopeArray.splice(id, 1);
    });
  }
  }
}
