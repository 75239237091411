import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { CustomersComponent } from './components/customers/customers.component';
import { LicenseDetailComponent } from './components/license-detail/license-detail.component';
import { LicenseEditComponent } from './components/license-edit/license-edit.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LicenseAddComponent } from './components/license-add/license-add.component';
import { CustomerAddComponent } from './components/customer-add/customer-add.component';
import { CustomerEditComponent } from './components/customer-edit/customer-edit.component';
import { ProductsComponent } from './components/products/products.component';
import { ProductEditComponent } from './components/product-edit/product-edit.component';
import { ProductAddComponent } from './components/product-add/product-add.component';
import { ScopeComponent } from './components/scope/scope.component';
import { ScopeAddComponent } from './components/scope-add/scope-add.component';
import { ScopeEditeComponent } from './components/scope-edite/scope-edite.component';
import { LicenseComponent } from './components/license/license.component';
import { PackagesetsComponent } from './components/packagesets/packagesets.component';
import { AddPackagesetsComponent } from './components/add-packagesets/add-packagesets.component';
import { PackagesetsUpdateComponent } from './components/packagesets-update/packagesets-update.component';

import { PackageManagerAdminComponent } from './components/package-manager-admin/package-manager-admin.component';
import { UpdatesComponentComponent } from './components/updates/updates.component';
import { ChangesComponent } from './components/changes/changes.component';
import {PingLogsComponent} from './components/ping-logs/ping-logs.component';

const routes: Routes = [
  { path: '', component: CustomersComponent, canActivate: [AuthGuard] },
  { path: 'customer', component: CustomersComponent, canActivate: [AuthGuard] },
  { path: 'licenses/:id', component: LicenseComponent, canActivate: [AuthGuard] },
  { path: 'license/:id', component: LicenseDetailComponent, canActivate: [AuthGuard] },
  { path: 'license/:id/edit', component: LicenseEditComponent, canActivate: [AuthGuard] },
  { path: 'customer-create', component: CustomerAddComponent, canActivate: [AuthGuard] },
  { path: 'customer/:id/edit', component: CustomerEditComponent, canActivate: [AuthGuard] },
  { path: 'customer/:id/license/create', component: LicenseAddComponent, canActivate: [AuthGuard] },
  { path: 'products', component: ProductsComponent, canActivate: [AuthGuard] },
  { path: 'products-create', component: ProductAddComponent, canActivate: [AuthGuard] },
  { path: 'products/:id/edit', component: ProductEditComponent, canActivate: [AuthGuard] },
  { path: 'scope', component: ScopeComponent, canActivate: [AuthGuard] },
  { path: 'scope-create', component: ScopeAddComponent, canActivate: [AuthGuard] },
  { path: 'scope/:id/edit', component: ScopeEditeComponent, canActivate: [AuthGuard] },
  { path: 'packages', component: PackagesetsComponent, canActivate: [AuthGuard] },
  { path: 'packages-create', component: AddPackagesetsComponent, canActivate: [AuthGuard] },
  { path: 'packages/:id/edit', component: PackagesetsUpdateComponent, canActivate: [AuthGuard] },
  { path: 'package', component: PackageManagerAdminComponent, canActivate: [AuthGuard] },
  { path: 'updates', component: UpdatesComponentComponent, canActivate: [AuthGuard] },
  { path: 'changes', component: ChangesComponent, canActivate: [AuthGuard] },
  { path: 'ping-logs', component: PingLogsComponent, canActivate: [AuthGuard] },
  { path: '**', component: DashboardComponent, canActivate: [AuthGuard] }
];

@NgModule({
  exports: [
    RouterModule,
  ],
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  declarations: []
})
export class AppRoutingModule { }
