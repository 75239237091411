import { Pipe, PipeTransform } from '@angular/core';
import { State } from '../models/license';

@Pipe({
  name: 'enumStatePipe'
})
export class EnumStatePipePipe implements PipeTransform {

  transform(value: number, args?: any): string {
    return State[value];
  }

}
