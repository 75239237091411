import { Component, OnInit } from '@angular/core';
import { Scope } from '../../models/scope';
import { ScopeService } from '../../services/scope/scope.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ScopeAddComponent } from '../scope-add/scope-add.component';
import { ViewChild, ElementRef } from '@angular/core';
import { PackageService } from '../../services/package/package.service';
import { PackageResponse } from '../../services/package/package.response';
import { PackageSets } from '../../models/packagesets';


@Component({
  selector: 'app-scope-edite',
  templateUrl: './scope-edite.component.html',
  styleUrls: ['./scope-edite.component.css']
})
export class ScopeEditeComponent extends ScopeAddComponent implements OnInit {
  public packageResponse: PackageResponse[];
  public ind: number;
  public packageSet: PackageSets;
  public scope: Scope;
  @ViewChild('submitButton') submitButton: ElementRef;
  constructor(
    protected scopeService: ScopeService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected packageService: PackageService
  ) {
    super(scopeService, route, router, packageService);
    this.scope = new Scope();
    const id = this.route.snapshot.paramMap.get('id');
  }
  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');



    this.scopeService.getScope(id).then(scope => {
      this.scope = scope;
    }).catch(err => {
      console.log('Issue with loading customer', err);
    });
    this.packageService.getPackages().then(data => {
      this.packageResponse = data;
      console.log(data);
    });
    console.log(this.scope.packageSets[0].name);
  }



  public removePackage(id: number) {
    if (this.scope.packageSets[id]) {
      this.scope.packageSets.splice(id, 1);
    }
    // this.packageResponse.slice(id, 1);
  }

  public addPackage() {
    console.log(this.ind);
    if (this.packageResponse == null) { return; }

    if (this.packageResponse[this.ind]) {
      if (this.scope.packageSets.every(n => n.name !== this.packageResponse[this.ind].name)) {
        if (this.scope.packageSets == null) {
          this.scope.packageSets = new Array<PackageSets>();
        }
        this.packageSet = new PackageSets();
        this.packageSet.id = this.packageResponse[this.ind].id;
        // this.packageSet.Key = this.packageResponse[id].key;
        this.packageSet.name = this.packageResponse[this.ind].name;
        // this.packageSet.Sets = this.packageResponse[id].sets;
        this.packageSet.description = this.packageResponse[this.ind].description;
        this.scope.packageSets.push(this.packageSet);
        console.log(this.scope.packageSets);
        //this.newDomain = '';
      }
    }
  }



  public saveUpdate() {
    this.submitButton.nativeElement.disabled = true;
    console.log(this.scope);
    this.scopeService.updateScope(this.scope).then(() => {
      this.router.navigate(['scope']);
      this.submitButton.nativeElement.disabled = false;
    }).catch(err => {
      console.log(err);
      this.submitButton.nativeElement.disabled = false;
    });
  }
}
