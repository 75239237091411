import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { License } from '../../models/license';
import { LicenseService } from '../../services/license/license.service';

@Component({
  selector: 'app-license-detail',
  templateUrl: './license-detail.component.html',
  styleUrls: ['./license-detail.component.css']
})
export class LicenseDetailComponent implements OnInit {

  constructor(
    private licenseService: LicenseService,
    private route: ActivatedRoute
  ) { }

  public license: License;

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    this.licenseService.getLicense(id).then(license => {
      this.license = license;
    }).catch(err => {
      console.log('Issue with loading license', err);
    });
  }

  public getDate() {
    console.log(this.license.expiration);
    return new Date(this.license.expiration.year, this.license.expiration.month - 1, this.license.expiration.day);
  }
}
