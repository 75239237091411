import { PackageResponse, PackageDeleteResponse } from "../services/package/package.response";
import { PackageSets } from "../models/packagesets";
import { AddPackageRequest } from "../services/package/add.package.request";
import { UpdatePackageRequest } from "../services/package/update.product.request";

export class PackageFactory {

    /**
 * static create
 * @param model
 */
    public static create(model: PackageResponse): PackageSets {
        return {
            description: model.description,
            id: model.id,
            name: model.name,
            fileRepository: model.fileRepository,
            npm: model.npm,
            nuGet: model.nuGet
        };
    }


    //     /**
    //  * static create
    //  * @param model
    //  */
    // public static create(model: PackageSets): PackageResponse {
    //     return {
    //         description : model.Description,
    //         id : model.id,
    //         name : model.Name,
    //         sets : model.Sets
    //     };
    // }

    /**
     * createAddRequest
     * @param model
     */
    public static createAddRequest(model: PackageSets): AddPackageRequest {
        return {
            name: model.name,
            description: model.description,
            fileRepository: model.fileRepository,
            npm: model.npm,
            nuGet: model.nuGet
        };
    }

    /**
     *  createUpdateRequest
     * @param model
     */
    public static createUpdateRequest(model: PackageSets): UpdatePackageRequest {
        return {
            description: model.description,
            name: model.name,
            fileRepository: model.fileRepository,
            npm: model.npm,
            nuGet: model.nuGet
        };
    }

    public static delete(model: PackageResponse): PackageDeleteResponse {
        return {
            id: model.id,
            description: model.description,
            name: model.name,
            fileRepository: model.fileRepository,
            npm: model.npm,
            nuGet: model.nuGet
        };
    }

}