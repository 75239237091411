export class MasterMode {

    constructor(id?: string) {
        this.id = id;
    }

    id: string;
    packageManager?: PackageType;
    apiKey: string;
    userName: string;
    masterPassword: string;
}

export enum PackageType {
    Npm,
    NuGet,
    FileRepository
}
