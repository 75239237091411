import { CustomerResponse } from '../services/customer/customer.response';
import { Customer, KysStatuses } from '../models/customer';
import { AddCustomerRequest } from '../services/customer/add.customer.request';
import { UpdateCustomerRequest } from '../services/customer/update.customer.request';
import { ActivateCustomerRequest } from '../services/customer/activate.customer.request';

export class CustomerFactory {
    public static create(model: CustomerResponse): Customer {
        return {
            id: model.id,
            userName: model.userName,
            firstName: model.firstName,
            lastName: model.lastName,
            email: model.email,
            apiKey: model.apiKey,
            customerKey: model.customerKey,
            address: model.address,
            phone: model.phone,
            isActivate: model.isActivate,
            isRejected: model.isRejected,
            kysStatus: model.kysStatus
        };
    }

    public static createAddRequest(model: Customer): AddCustomerRequest {
        return {
            firstName: model.firstName,
            lastName: model.lastName,
            email: model.email,
            address: model.address,
            phone: model.phone,
            isActivate: model.isActivate
        };
    }

    public static createUpdateRequest(model: Customer): UpdateCustomerRequest {
        return {
            userName: model.userName,
            firstName: model.firstName,
            lastName: model.lastName,
            email: model.email,
            customerKey: model.customerKey,
            address: model.address,
            phone: model.phone
        };
    }

    public static activateRequest(model: Customer): ActivateCustomerRequest {
        return {
            userName: model.userName,
            firstName: model.firstName,
            lastName: model.lastName,
            email: model.email,
            customerKey: model.customerKey,
            address: model.address,
            phone: model.phone,
            isActivate: model.isActivate
        };
    }

}
