import { Component, OnInit } from '@angular/core';
import { Scope } from '../../models/scope';
import { ScopeService } from '../../services/scope/scope.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewChild, ElementRef } from '@angular/core';
import { PackageResponse } from '../../services/package/package.response';
import { PackageService } from '../../services/package/package.service';
import { PackageSets } from '../../models/packagesets';





@Component({
  selector: 'app-scope-add',
  templateUrl: './scope-add.component.html',
  styleUrls: ['./scope-add.component.css']
})
export class ScopeAddComponent implements OnInit {
  public packageResponse: PackageResponse[];
  public ind: number;
  public property: number;
  public packageSet: PackageSets;
  public scope: Scope;
  @ViewChild('submitButton') submitButton: ElementRef;
  constructor(
    protected scopeService: ScopeService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected packageService: PackageService
  ) {
    this.packageResponse = new PackageResponse()[0];
    this.scope = new Scope();
    this.packageSet = new PackageSets();
  }

  ngOnInit() {
    this.packageService.getPackages().then(data => {
      this.packageResponse = data;
      console.log(data);
    })
  }


  public removePackage(id: number) {
    console.log(id);
    //const index: number = this.scope.packageSets[id]indexOf(set);
    this.scope.packageSets.splice(id, 1);
    // this.packageResponse.slice(id, 1);
  }

  public addPackage() {
    console.log(this.property);

    if (this.packageResponse == null) { return; }
    if (this.packageResponse[this.property]) {
      if (this.scope.packageSets.every(n => n.name !== this.packageResponse[this.property].name)) {

        if (this.scope.packageSets == null) {
          this.scope.packageSets = new Array<PackageSets>();
        }
        this.packageSet = new PackageSets();
        this.packageSet.id = this.packageResponse[this.property].id;
        // this.packageSet.Key = this.packageResponse[id].key;
        this.packageSet.name = this.packageResponse[this.property].name;
        // this.packageSet.Sets = this.packageResponse[id].sets;
        this.packageSet.description = this.packageResponse[this.property].description;
        this.scope.packageSets.push(this.packageSet);
        console.log(this.scope.packageSets);
        //this.newDomain = '';
      }
    }
  }


  public save() {
    this.submitButton.nativeElement.disabled = true;
    this.scopeService.addScope(this.scope).then((scope) => {
      if (!scope) {
        console.log('Fix issue with adding customer');
        this.submitButton.nativeElement.disabled = false;
      }
      this.router.navigate(['scope']);
      this.submitButton.nativeElement.disabled = false;
    }).catch(err => {
      this.submitButton.nativeElement.disabled = false;
      console.log(err);
    });
  }
}
