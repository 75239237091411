// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

const debug = false;

export const environment = debug ? {
  production: false,
  // baseUrl: 'http://localhost:4550/api',
  // authUrl: 'http://localhost:4580',
  baseUrl: 'http://fintatech-licensing-customers-api:4509/api',
  authUrl: 'http://fintatech-licensing-admin:4580',
  identityUrl: 'http://localhost:57835',
  pingUrl: 'https://ping.fintatech.com/api'
} : {
  production: false,
  baseUrl: 'https://customers.fintatech.com/api',
  authUrl: 'https://mgmt.fintatech.com',
  identityUrl: 'https://login.fintatech.com/',
  pingUrl: 'https://ping.fintatech.com/api'
};
