import { Injectable } from '@angular/core';
import { DataService } from '../data.service';
import { MasterMode, PackageType } from '../../models/master';
import { MasterFactory } from '../../factories/master.factory';

@Injectable()
export class MasterService {

    constructor(private dataService: DataService) { }

    /**
    * Get config by id
    *@param type type string
   */
    public getMasterCongfig(type: PackageType): Promise<MasterMode> {
        return new Promise((resolve, reject) => {
            this.dataService.getMaster(type).then(conf => {
                return conf ? resolve(conf) : reject();
            });
        });
    }


    /**
    * Update master config
    * @param master
    */
    public updateMasterConfig(master: MasterMode): Promise<MasterMode> {
        return new Promise((resolve, reject) => {
            this.dataService.updateMasterConfig(master.id, MasterFactory.createUpdateRequest(master)).then(response => {
                return master ? resolve(master) : reject();
            }).catch(reject);
        });
    }

    /**
     * Methot for refresh apu key
     * @param id
     */
    public refreshApiKey(id: string): Promise<MasterMode> {
        return new Promise((resolve, reject) => {
            this.dataService.refreshApiKey(id).then(response => {
                resolve(response);
            }).catch(reject);
        });
    }


      /**
     * Methot for refresh password
     * @param id
     */
    public refreshPassword(id: string): Promise<MasterMode> {
        return new Promise((resolve, reject) => {
            this.dataService.refreshPassword(id).then(response => {
                resolve(response);
            }).catch(reject);
        });
    }
}
