export class PackageSets {
    constructor(id?: string) {
        this.id = id;
        this.nuGet = [],
        this.npm = [],
        this.fileRepository = []
    }

    public id: string;
    public name: string;
    public description: string
    public nuGet: Array<string>;
    public npm: Array<string>;
    public fileRepository: Array<string>;
}