import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../../services/customer/customer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Customer } from '../../models/customer';
import { CustomerAddComponent } from '../customer-add/customer-add.component';

@Component({
  selector: 'app-customer-edit',
  templateUrl: './customer-edit.component.html',
  styleUrls: ['./customer-edit.component.css']
})
export class CustomerEditComponent extends CustomerAddComponent implements OnInit {

  public customer: Customer;

  constructor(
    protected customerService: CustomerService,
    protected route: ActivatedRoute,
    protected router: Router
  ) {
    super(customerService, route, router);
    const id = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    this.customerService.getCustomer(id).then(customer => {
      this.customer = customer;
    }).catch(err => {
      console.log('Issue with loading customer', err);
    });
  }

  public save() {
    this.customerService.updateCustomer(this.customer).then(() => {
      this.router.navigate(['/']);
    }).catch(err => {
      console.log(err);
    });
  }

}
