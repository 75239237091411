import { Component, OnInit } from '@angular/core';
import { Update } from '../../models/update';
import { UpdateService } from '../../services/update/update.service';
import { MatDialog } from '@angular/material/dialog';
import { LinkComponent } from '../link/link.component';
import { StatusInfo } from '../../services/update/status.info.response';
import { IntervalInfo } from '../../services/update/interval.info.response';

@Component({
  selector: 'app-updates',
  templateUrl: './updates.component.html',
  styleUrls: ['./updates.component.css']
})
export class UpdatesComponentComponent implements OnInit {

  public updateArray: Update[] = [];
  public statusInfo: StatusInfo;
  public intervalInfo: IntervalInfo;

  constructor(private updateService: UpdateService, public dialog: MatDialog) {
    this.updateArray = [];
    this.statusInfo = new StatusInfo();
    this.intervalInfo = new IntervalInfo();
    this.intervalInfo.nextNotify = null;
  }

  ngOnInit() {
    this.updateService.getUpdates().then(data => {
      this.updateArray =  data;
    });
    this.updateService.getStatusInfo().then(info => {
      this.statusInfo = info;
    });
    this.updateService.getInterval().then(interval => {
      this.intervalInfo = interval;
    });
    console.log(this.intervalInfo);

  }
  openDialog(infos: string[]) {
    this.dialog.open(LinkComponent, {
      data: infos
    });
  }

  Notified(update: Update) {
    const updateId = update.id;
    this.updateService.notifiedUpdate(updateId, update);
  }

  setInterval() {
    console.log(this.intervalInfo);
     this.updateService.setInterval(this.intervalInfo);
  }
}
