import { Injectable } from '@angular/core';
import { License } from '../../models/license';
import { Customer, KysStatusFlags } from '../../models/customer';
import { DataService } from '../data.service';
import { LicenseFactory } from '../../factories/license.factory';
import { CustomerFactory } from '../../factories/customer.factory';

@Injectable()
export class CustomerService {

    constructor(private dataService: DataService) {

    }
    /**
     * Get customer by id
     * @param id
    */
    public getCustomer(id: string): Promise<Customer> {
      return new Promise((resolve, reject) => {
            this.dataService.getCustomer(id).then(customer => {
                return customer ? resolve(customer) : reject();
            });
        });
    }
    /**
    * Get customer by id
    * @param status
    */
    public getCustomers(status: KysStatusFlags): Promise<Customer[]> {
        return new Promise((resolve, reject) => {
            this.dataService.getCustomers(status).then(customer => {
                return customer ? resolve(customer) : reject();
            });
        });
    }

    /**
    *
    * @param license
    */
    public deleteCustomer(id: string): Promise<Customer> {
        return new Promise((resolve, reject) => {
            this.dataService.deleteCustomer(id).then(response => {
                if (response) { resolve(CustomerFactory.create(response)); }
            }).catch(reject);
        });
    }

    /**
     * Update customer
     * @param customer
     */
    public updateCustomer(customer: Customer): Promise<Customer> {
        return new Promise((resolve, reject) => {
            this.dataService.updateCustomer(customer.id, CustomerFactory.createUpdateRequest(customer)).then(response => {
                return customer ? resolve(customer) : reject();
            }).catch(reject);
        });
    }

    /**
     * Create customer
     * @param customer
     */
    public createCustomer(customer: Customer): Promise<Customer> {
        return new Promise((resolve, reject) => {
            this.dataService.addCustomer(CustomerFactory.createAddRequest(customer)).then(response => {
                return customer ? resolve(customer) : reject();
            }).catch(reject);
        });
    }

    /**
     * Get licenses by customer id
     * @param id customer id
     */
    public getLicensesByCustomeId(id: string): Promise<License[]> {
        return new Promise((resolve, reject) => {
            this.dataService.getCustomerLicenses(id)
                .then(response => {
                    const licenses = LicenseFactory.createModels(response);
                    return licenses ? resolve(licenses) : reject();
                }).catch(reject);
        });
    }

    /**
     * Put mehtod for activate user
     */
    public isActivateCustomers(customer: Customer): Promise<Customer> {
        return new Promise((resolve, reject) => {
            this.dataService.activateCustomers(customer.id, CustomerFactory.activateRequest(customer)).then(response => {
                return customer ? resolve(customer) : reject();
            }).catch(reject);
        });
    }

    public getPendingCount(): Promise<Number> {
        return this.dataService.getPendingCount().toPromise();
    }
}
