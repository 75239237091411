import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PackageSets } from '../../models/packagesets';
import { PackageService } from '../../services/package/package.service';
import { PackageResponse } from '../../services/package/package.response';
import { AddPackagesetsComponent } from '../add-packagesets/add-packagesets.component';


@Component({
  selector: 'app-packagesets-update',
  templateUrl: './packagesets-update.component.html',
  styleUrls: ['./packagesets-update.component.css']
})
export class PackagesetsUpdateComponent implements OnInit {
  @ViewChild('submitButton') submitButton: ElementRef;

  public packageRes: PackageResponse;
  public newSet: string;
  public set: string;
  public properties: string[];
  public property: string;

  constructor(protected packageService: PackageService,
    protected route: ActivatedRoute,
    protected router: Router) {
    const id = this.route.snapshot.paramMap.get('id');
    this.properties = ["NuGet", "npm", "FileRepository"];

    this.packageRes = new PackageResponse();

  }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    this.packageService.getPackage(id).then(gettedPackage => {
      this.packageRes = gettedPackage;
      console.log(gettedPackage);
    }).catch(err => {
      console.log(err);
    });
    this.packageRes.id = id;
  }

  public removeSet(prop: string, set: string) {
    if (this.packageRes[prop]) {
      const index: number = this.packageRes[prop].indexOf(set);
      if (index !== -1) {
        this.packageRes[prop].splice(index, 1);
      }
    }
  }


  public addSet() {
    if(this.set===""){
      return;
    }
    switch (this.property) {
      case "NuGet": {
        if(!this.packageRes["nuGet"].every(n=>n!==this.set)){
          this.set=""
          break;
        }
        this.packageRes["nuGet"].push(this.set);
        this.set = '';
        break;
      }
      case "npm": {
        if(!this.packageRes["npm"].every(n=>n!==this.set)){
          this.set=""
          break;
        }
        this.packageRes["npm"].push(this.set);
        this.set = '';
        break;
      }
      case "FileRepository": {
        if(!this.packageRes["fileRepository"].every(n=>n!==this.set)){
          this.set=""
          break;
        }
        this.packageRes["fileRepository"].push(this.set);
        this.set = '';
        break;
      }
    }
    // if (this.packageRes[this.property]) {
    //   this.packageRes[this.property].push(this.set);
    //   this.set = '';
    // }
  }



  public saveUpdate() {
    const updatedPackage = new PackageSets();
    updatedPackage.name = this.packageRes.name;
    updatedPackage.description = this.packageRes.description;
    updatedPackage.npm = this.packageRes.npm;
    updatedPackage.nuGet = this.packageRes.nuGet;
    updatedPackage.fileRepository = this.packageRes.fileRepository;
    updatedPackage.id = this.packageRes.id;
    this.submitButton.nativeElement.disabled = true;
    console.log(updatedPackage);
    this.packageService.updatePackage(updatedPackage).then(() => {
      this.router.navigate(['/packages']);
      this.submitButton.nativeElement.disabled = false;
    }).catch(err => {
      console.log(err);
      this.submitButton.nativeElement.disabled = false;
    });
  }

}
