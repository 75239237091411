import { Customer } from './customer';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ProductDto } from './products';
import { Scope } from './scope';

export class License {
    constructor(customerId?: string) {
        this.domains = [];
        this.customerId = customerId;
        const now = new Date();
        this.expiration = {
            year: now.getFullYear(),
            day: now.getDay(),
            month: now.getMonth()
        };
    }

    id: string;
    product: ProductDto = new ProductDto;
    productId: string;
    scopes: Scope[] = [];
    customerId?: string;
    domains: string[] = [];
    expiration: NgbDateStruct;
    note: string;
    hidden:boolean;

}

export enum State {
    Blocked = 0,
    Expired = 1,
    Active = 2
}
