import { Component, OnInit } from '@angular/core';
import { ProductDto } from '../../models/products';
import { ActivatedRoute } from '@angular/router';
import { Link } from '../../models/links';
import { License } from '../../models/license';
import { LinkComponent } from '../link/link.component';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProductService } from '../../services/product/product.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  public productsArray: ProductDto[];

  constructor(private productService: ProductService, private route: ActivatedRoute, public dialog: MatDialog) {
    this.productsArray = new ProductDto()[0];
  }

  ngOnInit() {
    this.productService.getProducts().then(data => {
      this.productsArray =  data;
      console.log(this.productsArray);
    });
  }
  openDialog(infos: string[]) {
    this.dialog.open(LinkComponent, {
      data: infos
    });
  }

  public deleteItem(id: number,name:string) {
    if(confirm("Are you sure to delete product: "+name+" ?")){
    const productsArray = this.productsArray[id];
    this.productService.deleteProduct(productsArray.id).then(c => {
      this.productsArray.splice(id, 1);
    });
  }
  }
}
