import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PackageSets } from '../../models/packagesets';
import { PackageService } from '../../services/package/package.service';


@Component({
  selector: 'app-add-packagesets',
  templateUrl: './add-packagesets.component.html',
  styleUrls: ['./add-packagesets.component.css']
})
export class AddPackagesetsComponent implements OnInit {

  @ViewChild('submitButton') submitButton: ElementRef;

  public property: string;
  public ind: number;
  public packageResp: PackageSets;
  public set: string = "";
  public properties: string[] = [];

  constructor(protected packageService: PackageService, protected route: ActivatedRoute,
    protected router: Router) {
    this.packageResp = new PackageSets();

    this.properties = ["NuGet", "npm", "FileRepository"];
  }

  ngOnInit() {
  }

  public removeSet(prop: number, set: string) {

    switch (prop) {
      case 0: {
        if (this.packageResp["npm"]) {
          const index: number = this.packageResp["npm"].indexOf(set);
          if (index !== -1) {
            this.packageResp["npm"].splice(index, 1);
          }
        }
        break;
      }
      case 1: {
        if (this.packageResp["nuGet"]) {
          const index: number = this.packageResp["nuGet"].indexOf(set);
          if (index !== -1) {
            this.packageResp["nuGet"].splice(index, 1);
          }
        }
        break;
      }
      case 2: {
        if (this.packageResp["fileRepository"]) {
          const index: number = this.packageResp["fileRepository"].indexOf(set);
          if (index !== -1) {
            this.packageResp["fileRepository"].splice(index, 1);
          }
        }
        break;
      }
    }
  }


  public addSet() {
    if(this.set===""){
      return;
    }
    switch (this.property) {
      case "NuGet": {
        if(!this.packageResp["nuGet"].every(n=>n!==this.set)){
          this.set=""
          break;
        }
        this.packageResp["nuGet"].push(this.set);
        this.set = '';
        break;
      }
      case "npm": {
        if(!this.packageResp["npm"].every(n=>n!==this.set)){
          this.set=""
          break;
        }
        this.packageResp["npm"].push(this.set);
        this.set = '';
        break;
      }
      case "FileRepository": {
        if(!this.packageResp["fileRepository"].every(n=>n!==this.set)){
          this.set=""
          break;
        }
        this.packageResp["fileRepository"].push(this.set);
        this.set = '';
        break;
      }
    }
  }
  public save() {
    this.submitButton.nativeElement.disabled = true;
    this.packageResp.name = this.packageResp.name;
    this.packageService.addPackage(this.packageResp).then((newpackage) => {
      if (!newpackage) {
        console.log("error in creating package");
      }
      this.router.navigate(['packages']);
      this.submitButton.nativeElement.disabled = false;

    }).catch(err => {
      this.submitButton.nativeElement.disabled = false;
      console.log(err);
    })
  }
}
