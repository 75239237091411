import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../../services/customer/customer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Customer } from '../../models/customer';
import { ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-customer-add',
  templateUrl: './customer-add.component.html',
  styleUrls: ['./customer-add.component.css']
})
export class CustomerAddComponent implements OnInit {

  public customer: Customer;
  @ViewChild('submitButton') submitButton: ElementRef;
  constructor(
    protected customerService: CustomerService,
    protected route: ActivatedRoute,
    protected router: Router
  ) { }

  ngOnInit() {
    this.customer = new Customer();
  }

  public save() {
    this.submitButton.nativeElement.disabled = true;
    this.customer.isActivate = true;
    this.customerService.createCustomer(this.customer).then((customer) => {
      if (!customer) {
        console.log('Fix issue with adding customer');
      }
      this.router.navigate(['/']);
      this.submitButton.nativeElement.disabled = true;
    }).catch(err => {
      console.log(err);
      this.submitButton.nativeElement.disabled = false;
    });
  }

}
