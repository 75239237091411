import { Component, OnInit } from '@angular/core';
import { ProductDto } from '../../models/products';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from '../../services/product/product.service';
import { ProductAddComponent } from '../product-add/product-add.component';
import { Link } from '../../models/links';
import { Scope } from '../../models/scope';
import { ScopeService } from '../../services/scope/scope.service';
import { ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.css']
})
export class ProductEditComponent extends ProductAddComponent implements OnInit {
  @ViewChild('submitButton') submitButton: ElementRef;
  public product: ProductDto;
  public currencies: Array<string>;
  public hidden: boolean;
  constructor(
    protected productService: ProductService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected scopeService: ScopeService
  ) {
    super(productService, scopeService, route, router);
    const id = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    this.currencies = new Array('EUR', 'USD');
    const productId = this.route.snapshot.paramMap.get('id');
    this.productService.getProduct(productId).then(item => {
      this.product = item;
      this.hidden = item.hidden;
    }).catch(err => {
      console.log('Issue with loading customer', err);
    });
  }

  public clickHidden(i: boolean) {
    this.hidden = i;
  }
  public saveUpdate() {
    this.submitButton.nativeElement.disabled = true;
    this.product.hidden = this.hidden;
    this.productService.updateProduct(this.product).then(() => {
      if (this.product) {
        this.router.navigate(['products']);
        this.submitButton.nativeElement.disabled = false;
      }
    }).catch(err => {
      console.log(err);
      this.submitButton.nativeElement.disabled = false;
    });
  }
}
