import { Injectable } from '@angular/core';
import { License } from '../../models/license';
import { Customer } from '../../models/customer';
import { DataService } from '../data.service';
import { Observable } from 'rxjs';
import { LicenseFactory } from '../../factories/license.factory';

@Injectable()
export class LicenseService {

    constructor(private dataService: DataService) {

    }
    /**
     * Get license by id
     * @param id
    */

    public getLicense(id: string): Promise<License> {
        return new Promise((resolve, reject) => {
            this.dataService.getLicense(id).then(licenseResponse => {
                if (!licenseResponse) { return reject(id); }
                const license = LicenseFactory.create(licenseResponse);
                this.dataService.getCustomer(licenseResponse.customerId).then(customer => {
                    license.customerId = customer.id;
                    return resolve(license);
                });
            });
        });
    }


    /**
     *
     * @param license
     */
    public deleteLicense(id: string): Promise<License> {
        return new Promise((resolve, reject) => {
            this.dataService.deleteLicense(id).then(response => {
                if (response) { resolve(LicenseFactory.create(response)); }
            }).catch(reject);
        });
    }


    /**
     *
     * @param license
     */
    public updateLicense(license: License): Promise<License> {
        return new Promise((resolve, reject) => {
            this.dataService.updateLicense(license.id, LicenseFactory.createUpdateRequest(license)).then(response => {
                if (response) { resolve(license); }
            }).catch(reject);
        });
    }

    public createLicense(license: License): Promise<License> {
        return new Promise((resolve, reject) => {
            this.dataService.addLicense(LicenseFactory.createAddRequest(license)).then(response => {
                if (response) { resolve(LicenseFactory.create(response)); }
            }).catch(reject);
        });
    }
}
