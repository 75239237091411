import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataService } from '../data.service';
import { Update } from '../../models/update';
import { Change } from '../../models/change';
import { UpdateFactory } from '../../factories/update.factory';
import { StatusInfo } from '../update/status.info.response';
import { IntervalInfo } from './interval.info.response';

@Injectable()
export class UpdateService {

    constructor(private dataService: DataService) { }

    /**
     * Get interval
     */
    public getInterval(): Promise<IntervalInfo> {
        return new Promise((resolve, reject) => {
            this.dataService.getInterval().then(status => {
                return status ? resolve(status) : reject();
            });
        });
    }
/**
     * Set interval
     */
    public setInterval(model: IntervalInfo ): Promise<IntervalInfo> {
        return new Promise((resolve, reject) => {
            this.dataService.setInterval(model).then(status => {
                return status ? resolve(status) : reject();
            });
        });
    }


    /**
     * Get status
     */
    public getStatusInfo(): Promise<StatusInfo> {
        return new Promise((resolve, reject) => {
            this.dataService.getStatusInfo().then(status => {
                return status ? resolve(status) : reject();
            });
        });
    }

    /**
     * Get all updates
     */
    public getUpdates(): Promise<Update[]> {
        return new Promise((resolve, reject) => {
            this.dataService.getUpdates().then(update => {
                return update ? resolve(update) : reject();
            });
        });
    }

    /**
    * Get Update by id
    *@param id type string
   */
    public getUpdate(id: string): Promise<Update> {
        return new Promise((resolve, reject) => {
            this.dataService.getUpdate(id).then(update => {
                return update ? resolve(update) : reject();
            });
        });
    }
    /**
     * Rollback Change
     * @param id
     */
    public deleteChange(id: string): Promise<Change> {
        return new Promise((resolve, reject) => {
            this.dataService.deleteChange(id).then(response => {
                if (response) { resolve(UpdateFactory.deleteChangeResponse(response)); }
            }).catch(reject);
        });
    }

   /**
    * Notified by id
    *@param id type string
    */
   public notifiedUpdate(id: string, model: Update  ): Promise<Update> {
    return new Promise((resolve, reject) => {
        this.dataService.notifiedUpdate(id, UpdateFactory.create(model)).then(update => {
            return update ? resolve(UpdateFactory.create(update)) : reject();
        });
    });
}
}


