
import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {LicenseResponse} from './license/license.response';
import {Customer, KysStatusFlags} from '../models/customer';
import {AddLicenseRequest} from './license/add.license.request';
import {UpdateLicenseRequest} from './license/update.license.request';
import {environment} from '../../environments/environment';
import {UpdateCustomerRequest} from './customer/update.customer.request';
import {AddCustomerRequest} from './customer/add.customer.request';
import {CustomerResponse} from './customer/customer.response';
import {OAuthService} from 'angular-oauth2-oidc';
import {ProductDto} from '../models/products';
import {ProductResponse, ProductDeleteResponse} from './product/product.response';
import {UpdateProductRequest} from './product/update.product.request';
import {AddProductRequest} from './product/add.product.request';
import {ScopeResponse} from './scope/scope.response';
import {AddScopeRequest} from './scope/add.scope.request';
import {Scope} from '../models/scope';
import {UpdateScopeRequest} from './scope/update.scope.request';
import {PackageResponse, PackageDeleteResponse} from './package/package.response';
import {PackageSets} from '../models/packagesets';
import {AddPackageRequest} from './package/add.package.request';
import {UpdatePackageRequest} from './package/update.product.request';
import {MasterResponse} from './master/master.response';
import {UpdateMasterRequest} from './master/update.master.request';
import {MasterMode, PackageType} from '../models/master';
import {ActivateCustomerRequest} from './customer/activate.customer.request';
import {ChangeDeleteResponce} from './change/change.delete.responce';
import {UpdateResponse} from './update/update.responce';
import {StatusInfo} from './update/status.info.response';
import {IntervalInfo} from './update/interval.info.response';
import {QueryParamsConstructor} from '../utils/QueryParamsConstructor';

@Injectable()
export class DataService {

  private _baseUrl: string;
  private _identityUrl: string;

  constructor(private http: HttpClient, private _auth: OAuthService) {
    this._baseUrl = environment.baseUrl;
    this._identityUrl = environment.identityUrl;
  }

  public notifiedUpdate(id: string, model: UpdateResponse): Promise<UpdateResponse> {
    const endpoint = `${this._baseUrl}/update/notify/${id}`;
    return this.http.post<UpdateResponse>(endpoint, model, {headers: this.prepareRequestHeaders()})
      .toPromise()
      .catch(err => this._handleError(err));
  }

  public setInterval(model: IntervalInfo): Promise<IntervalInfo> {
    const endpoint = `${this._baseUrl}/job/interval`;
    return this.http.post<IntervalInfo>(endpoint, model, {headers: this.prepareRequestHeaders()})
      .toPromise()
      .catch(err => this._handleError(err));
  }

  public getInterval(): Promise<IntervalInfo> {
    const endpoint = `${this._baseUrl}/job/interval`;
    return this.http.get<IntervalInfo>(endpoint, {headers: this.prepareRequestHeaders()})
      .toPromise()
      .catch(err => this._handleError(err));
  }

  public getUpdate(id: string): Promise<UpdateResponse> {
    const endpoint = `${this._baseUrl}/update/${id}`;
    return this.http.get<UpdateResponse>(endpoint, {headers: this.prepareRequestHeaders()})
      .toPromise()
      .catch(err => this._handleError(err));
  }

  public getStatusInfo(): Promise<StatusInfo> {
    const endpoint = `${this._baseUrl}/job`;
    return this.http.get<StatusInfo>(endpoint, {headers: this.prepareRequestHeaders()})
      .toPromise()
      .catch(err => this._handleError(err));
  }


  /**
   * Method for rollback change
   * @param id type string
   */
  public deleteChange(id: string): Promise<ChangeDeleteResponce> {
    const endpoint = `${this._baseUrl}/change/${id}`;
    return this.http.delete<ChangeDeleteResponce>(endpoint, {headers: this.prepareRequestHeaders()})
      .toPromise()
      .catch(err => this._handleError(err));
  }

  /**
   * Method for get all updates
   */
  public getUpdates(): Promise<UpdateResponse[]> {
    const endpoint = `${this._baseUrl}/update`;
    return this.http.get<UpdateResponse[]>(endpoint, {headers: this.prepareRequestHeaders()})
      .toPromise()
      .catch(err => this._handleError(err));
  }


  public getLicense(id: string): Promise<LicenseResponse> {
    const endpoint = `${this._baseUrl}/license/${id}`;
    return this.http.get<LicenseResponse>(endpoint, {headers: this.prepareRequestHeaders()})
      .toPromise()
      .catch(err => this._handleError(err));
  }

  public addLicense(model: AddLicenseRequest): Promise<LicenseResponse> {
    const endpoint = `${this._baseUrl}/license`;
    return this.http.post<LicenseResponse>(endpoint, model, {headers: this.prepareRequestHeaders()})
      .toPromise()
      .catch(err => this._handleError(err));
  }


  public deleteLicense(id: string): Promise<LicenseResponse> {
    const endpoint = `${this._baseUrl}/license/${id}`;
    return this.http.delete<LicenseResponse>(endpoint, {headers: this.prepareRequestHeaders()})
      .toPromise()
      .catch(err => this._handleError(err));
  }

  public updateLicense(id: string, model: UpdateLicenseRequest): Promise<LicenseResponse> {
    const endpoint = `${this._baseUrl}/license/${id}`;
    return this.http.put<LicenseResponse>(endpoint, model, {headers: this.prepareRequestHeaders()})
      .toPromise()
      .catch(err => this._handleError(err));
  }

  public deleteCustomer(id: string): Promise<CustomerResponse> {
    const endpoint = `${this._baseUrl}/customer/${id}`;
    return this.http.delete<CustomerResponse>(endpoint, {headers: this.prepareRequestHeaders()})
      .toPromise()
      .catch(err => this._handleError(err));
  }

  public getCustomer(id: string): Promise<Customer> {
    const endpoint = `${this._baseUrl}/customer/${id}`;
    return this.http.get<Customer>(endpoint, {headers: this.prepareRequestHeaders()})
      .toPromise()
      .catch(err => this._handleError(err));
  }

  public addCustomer(model: AddCustomerRequest): Promise<Customer> {
    const endpoint = `${this._baseUrl}/customer`;
    return this.http.post<Customer>(endpoint, model, {headers: this.prepareRequestHeaders()})
      .toPromise()
      .catch(err => this._handleError(err));
  }

  public updateCustomer(id: string, model: UpdateCustomerRequest): Promise<Customer> {
    const endpoint = `${this._baseUrl}/customer/${id}`;
    return this.http.put<Customer>(endpoint, model, {headers: this.prepareRequestHeaders()})
      .toPromise()
      .catch(err => this._handleError(err));
  }

  public getCustomers(status: KysStatusFlags): Promise<CustomerResponse[]> {
    const endpoint = `${this._baseUrl}/customer`;
    return this.http
      .get<CustomerResponse[]>(endpoint, {
        headers: this.prepareRequestHeaders(),
        params: QueryParamsConstructor.fromObject({status})
      })
      .toPromise()
      .catch(err => this._handleError(err));
  }

  public getCustomerLicenses(id: string): Promise<LicenseResponse[]> {
    const endpoint = `${this._baseUrl}/license/customer/${id}`;
    return this.http.get<LicenseResponse[]>(endpoint, {headers: this.prepareRequestHeaders()})
      .toPromise()
      .catch(err => this._handleError(err));
  }


  public activateCustomers(id: string, model: ActivateCustomerRequest): Promise<Customer> {
    const endpoint = `${this._baseUrl}/customer/activate/${id}`;
    return this.http.put<Customer>(endpoint, model, {headers: this.prepareRequestHeaders()})
      .toPromise()
      .catch(err => this._handleError(err));
  }

  // Methods for Products view

  /**
   *  Method for add product
   * @param model type of AddProductRequest
   */
  public addProduct(model: AddProductRequest): Promise<ProductDto> {
    const endpoint = `${this._baseUrl}/product`;
    const res = this.http.post<ProductDto>(endpoint, model, {headers: this.prepareRequestHeaders()})
      .toPromise()
      .catch(err => this._handleError(err));
    console.log(res);
    return res;
  }

  /**
   * Method for update product
   * @param id type string
   * @param modetl type UpdateProductRequest
   */
  public updateProduct(id: string, model: UpdateProductRequest): Promise<ProductDto> {
    const endpoint = `${this._baseUrl}/product/${id}`;
    return this.http.put<ProductDto>(endpoint, model, {headers: this.prepareRequestHeaders()})
      .toPromise()
      .catch(err => this._handleError(err));
  }

  /**
   * Method for get all products
   */
  public getProducts(): Promise<ProductResponse[]> {
    const endpoint = `${this._baseUrl}/product`;
    return this.http.get<ProductResponse[]>(endpoint, {headers: this.prepareRequestHeaders()})
      .toPromise()
      .catch(err => this._handleError(err));
  }

  /**
   * Method for get produc by id
   * @param id product id type string
   */
  public getProduct(id: string): Promise<ProductResponse> {
    const endpoint = `${this._baseUrl}/product/${id}`;
    return this.http.get<ProductResponse>(endpoint, {headers: this.prepareRequestHeaders()})
      .toPromise()
      .catch(err => this._handleError(err));
  }

  /**
   * Method for delete product
   * @param id
   */
  public deleteProduct(id: string): Promise<ProductDeleteResponse> {
    const endpoint = `${this._baseUrl}/product/${id}`;
    return this.http.delete<ProductDeleteResponse>(endpoint, {headers: this.prepareRequestHeaders()})
      .toPromise()
      .catch(err => this._handleError(err));
  }

  // Method for Article

  /**
   *  Method for add scope
   * @param model type of AddActicleRequest
   */
  public addScope(model: AddScopeRequest): Promise<Scope> {
    const endpoint = `${this._baseUrl}/scope`;
    return this.http.post<Scope>(endpoint, model, {headers: this.prepareRequestHeaders()})
      .toPromise()
      .catch(err => this._handleError(err));
  }

  /**
   * Method for get scope by id
   * @param id scope id type string
   */
  public getScopes(): Promise<ScopeResponse[]> {
    const endpoint = `${this._baseUrl}/scope`;
    console.log(endpoint);
    const res = this.http.get<ScopeResponse[]>(endpoint, {headers: this.prepareRequestHeaders()})
      .toPromise()
      .catch(err => this._handleError(err));

    return res;
  }

  /**
   * Method for get scope by id
   * @param id scope id type string
   */
  public getScope(id: string): Promise<ScopeResponse> {
    const endpoint = `${this._baseUrl}/scope/${id}`;
    return this.http.get<ScopeResponse>(endpoint, {headers: this.prepareRequestHeaders()})
      .toPromise()
      .catch(err => this._handleError(err));
  }

  /**
   * Method for delete scope
   * @param id scope id type string
   */
  public deleteScope(id: string): Promise<ScopeResponse> {
    const endpoint = `${this._baseUrl}/scope/${id}`;
    return this.http.delete<ScopeResponse>(endpoint, {headers: this.prepareRequestHeaders()})
      .toPromise()
      .catch(err => this._handleError(err));
  }

  /**
   * Method for update article
   * @param id type string
   * @param modetl type UpdateArticleRequest
   */
  public updateScope(id: string, model: UpdateScopeRequest): Promise<Scope> {
    const endpoint = `${this._baseUrl}/scope/${id}`;
    return this.http.put<Scope>(endpoint, model, {headers: this.prepareRequestHeaders()})
      .toPromise()
      .catch(err => this._handleError(err));
  }

  // Method for master config

  /**
   * Method for get info maser config by id
   * @param type admin id type string
   */
  public getMaster(type: PackageType): Promise<MasterResponse> {
    const endpoint = `${this._baseUrl}/master/${type}`;
    return this.http.get<MasterResponse>(endpoint, {headers: this.prepareRequestHeaders()})
      .toPromise()
      .catch(err => this._handleError(err));
  }


  /**
   * Method for update master config
   * @param id type string
   * @param modetl type UpdateMasterRequest
   */
  public updateMasterConfig(id: string, model: UpdateMasterRequest): Promise<MasterMode> {
    const endpoint = `${this._baseUrl}/master/${id}`;
    return this.http.put<MasterMode>(endpoint, model, {headers: this.prepareRequestHeaders()})
      .toPromise()
      .catch(err => this._handleError(err));
  }

  /**
   * Method for get refresh apikey
   * @param id admin id type string
   */
  public refreshApiKey(id: string): Promise<MasterResponse> {
    const endpoint = `${this._baseUrl}/master/generateApiKey/${id}`;
    return this.http.get<MasterResponse>(endpoint, {headers: this.prepareRequestHeaders()})
      .toPromise()
      .catch(err => this._handleError(err));
  }

  /**
   * Method for get refresh apikey
   * @param id admin id type string
   */
  public refreshPassword(id: string): Promise<MasterResponse> {
    const endpoint = `${this._baseUrl}/master/generatePassword/${id}`;
    return this.http.get<MasterResponse>(endpoint, {headers: this.prepareRequestHeaders()})
      .toPromise()
      .catch(err => this._handleError(err));
  }


  // Method for show error

  private _handleError(error) {
    console.error(error); // for demo purposes only
    if (error.status === 401) {
      this._auth.initImplicitFlow('/some-state;p1=1;p2=2');
    }
    return Promise.reject(error.message || error);
  }

  private prepareRequestHeaders(): HttpHeaders {
    const token = this._auth.getAccessToken();
    return new HttpHeaders({
      'Authorization': 'bearer ' + token
    });
  }


  public getPackages(): Promise<PackageResponse[]> {
    const endpoint = `${this._baseUrl}/packagesets`;
    const res = this.http.get<PackageResponse[]>(endpoint, {headers: this.prepareRequestHeaders()})
      .toPromise()
      .catch(err => this._handleError(err));
    console.log(res);
    return res;
  }

  /**
   *  Method for add scope
   * @param model type of AddPackageRequest
   */
  public addPackage(model: AddPackageRequest): Promise<PackageSets> {
    const endpoint = `${this._baseUrl}/packagesets`;
    return this.http.post<PackageSets>(endpoint, model, {headers: this.prepareRequestHeaders()})
      .toPromise()
      .catch(err => this._handleError(err));
  }

  /**
   * Method for delete packages
   * @param id
   */
  public deletePackage(id: string): Promise<PackageDeleteResponse> {
    const endpoint = `${this._baseUrl}/packagesets/${id}`;
    return this.http.delete<PackageDeleteResponse>(endpoint, {headers: this.prepareRequestHeaders()})
      .toPromise()
      .catch(err => this._handleError(err));
  }

  /**
   * Method for get produc by id
   * @param id product id type string
   */
  public updatePackage(id: string, model: UpdatePackageRequest): Promise<PackageSets> {
    const endpoint = `${this._baseUrl}/packagesets/${id}`;
    return this.http.put<PackageSets>(endpoint, model, {headers: this.prepareRequestHeaders()})
      .toPromise()
      .catch(err => this._handleError(err));
  }

  /**
   * Method for get package by id
   * @param id package id type string
   */
  public getPackage(id: string): Promise<PackageResponse> {
    const endpoint = `${this._baseUrl}/packagesets/${id}`;

    const res = this.http.get<PackageResponse>(endpoint, {headers: this.prepareRequestHeaders()})
      .toPromise()
      .catch(err => this._handleError(err));
    console.log(res);
    return res;
  }

  /**
   * Accept Customer
   * @param email
   */
  public acceptCustomer(email: string): Observable<any> {
    const endpoint = `${this._identityUrl}/account/activate`;

    return this.http
      .put<any>(endpoint, {}, {
        headers: this.prepareRequestHeaders(),
        params: QueryParamsConstructor.fromObject({email})
      }).pipe(
      catchError(err => this._handleError(err)));
  }

  /**
   * Reject Customer
   * @param email
   * @param reason
   */
  public rejectCustomer(email: string, reason: string): Observable<any> {
    const endpoint = `${this._identityUrl}/account/deactivate`;

    return this.http
      .put<any>(endpoint, {}, {
        headers: this.prepareRequestHeaders(),
        params: QueryParamsConstructor.fromObject({reason, email})
      }).pipe(
      catchError(err => this._handleError(err)));
  }

  public getPendingCount(): Observable<Number> {
    const endpoint = `${this._baseUrl}/customer/pending-count`;

    return this.http.get<Number>(endpoint, {headers: this.prepareRequestHeaders()}).pipe(
      catchError(err => this._handleError(err)));
  }
}
