import { PackageSets } from "./packagesets";

export class Scope {
    constructor(id?: string) {
        this.id = id;
        this.packageSets=[];
     }
     id: string;
     name: string;
     description: string;
     packageSets:Array<PackageSets>;
}
