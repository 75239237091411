import { Component, Inject, Input, OnChanges } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Change } from '../../models/change';
import { UpdateService } from '../../services/update/update.service';
/**
 * @title Injecting data when opening a dialog
 */
@Component({
  selector: 'app-changes',
  templateUrl: 'changes.component.html',
  styleUrls: ['changes.component.css']
})
export class ChangesComponent implements OnChanges {
  constructor(public dialog: MatDialog, private updateService: UpdateService) {}

  showChanges: Change[] = [];
  @Input() changesInfo: Change[];

  ngOnChanges(changes: any): void {
    if (changes['changesInfo'] && this.changesInfo) {
      this.changesInfo.forEach(item => {
        this.showChanges.push(item);
      });
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ChangesModalComponent, {
      maxWidth: '1000px',
      width: '700px',
      data:  this.showChanges
    });
  }
}

@Component({
  selector: 'app-changes-modal',
  templateUrl: 'changes-modal.html',
  styleUrls: ['changes.component.css']
})
export class ChangesModalComponent {
  constructor(
    public dialogRef: MatDialogRef<ChangesComponent>,
     private updateService: UpdateService,
    @Inject(MAT_DIALOG_DATA) public data: Change[]) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public rollbackChange(change: Change, index: number) {
    if ( confirm('Are you sure to rollback change ?' )) {
      const deletedChange = change;
      this.updateService.deleteChange(deletedChange.id).then(response => {
          if (response) {
            this.data.splice(index, 1);
            console.log(this.data);
          }
        });
      }
  }
}
