import { Component, OnInit } from '@angular/core';
import { MasterMode, PackageType } from '../../models/master';
import { EnumStatePipePipe } from '../../pipe/enum.state.pipe';
import { MasterService } from '../../services/master/master.service';

@Component({
  selector: 'app-package-manager-admin',
  templateUrl: './package-manager-admin.component.html',
  styleUrls: ['./package-manager-admin.component.css']
})
export class PackageManagerAdminComponent implements OnInit {

  public master: MasterMode;

  public typePackage = PackageType;

  constructor(
    protected masterService: MasterService,
  ) {
    this.master = new MasterMode;
  }

  ngOnInit() {
  }

  /**
   * loadMasterConfig
   */
  public loadMasterConfig() {
     this.masterService.getMasterCongfig(this.master.packageManager).then(data => {
      this.master.id = data.id;
      this.master.apiKey = data.apiKey;
      this.master.masterPassword = data.masterPassword;
      this.master.userName = data.userName;
      // console.log(this.productsArray);
    });
  }

  /**
   * Method for send get to server reguest refresh api key
   */
  public refreshApikey() {
    if (confirm('Are you sure to fresh api key?')) {
      this.masterService.refreshApiKey(this.master.id).then(data => {
        const response = data;
        this.master.apiKey = response.apiKey;
      });
    }
  }

  /**
   * Method for send get request to server for refresh user name
   */
  public refreshUserName() {
    if (confirm('Are you sure to fresh user name?')) {
     // this.masterService.refreshPassword(this.idMaster);
    }
  }

  /**
   * Method for send get request to server for password
   */
  public refreshPassword() {
    if (confirm('Are you sure to fresh password?')) {
      this.masterService.refreshPassword(this.master.id).then(data => {
        const response = data;
        this.master.masterPassword = response.masterPassword;
      });
    }
  }

  /**
   * Update
   */
  public update() {
    this.masterService.updateMasterConfig(this.master).then(data => {
        confirm('Config Update');
      });

  }
}
