import { ProductResponse, ProductDeleteResponse } from '../services/product/product.response';
import { ProductDto } from '../models/products';
import { AddProductRequest } from '../services/product/add.product.request';
import { UpdateProductRequest } from '../services/product/update.product.request';

export class ProductFactory {

    public static create(model: ProductResponse): ProductDto {
        return {
            id: model.id,
            currency: model.currency,
            description: model.description,
            price: model.price,
            scopes: model.scopeNames,
            hidden: model.hidden
        };
    }

    public static createAddRequest(model: ProductDto): AddProductRequest {
        return {
            description: model.description,
            currency: model.currency,
            price: model.price,
            hidden: model.hidden,
            scopeNames: model.scopes.map(scope => scope.name)
        };
    }

    public static createUpdateRequest(model: ProductDto): UpdateProductRequest {
        return {
            description: model.description,
            currency: model.currency,
            price: model.price,
            hidden: model.hidden
        };
    }
    public static delete(model: ProductDeleteResponse): ProductDto {
        return {
            id: model.id,
            currency: model.currency,
            description: model.description,
            price: model.price,
            hidden: model.hidden,
            scopes: model.scopeNames
        };
    }

}

