import { Injectable } from '@angular/core';
import { DataService } from '../data.service';
import { PackageSets } from '../../models/packagesets';
import { PackageFactory } from '../../factories/package.factory';
import { PackageResponse } from './package.response';

@Injectable()
export class PackageService {

    constructor(private dataService: DataService) { }

    /**
    * Get all packages
    */
    public getPackages(): Promise<PackageResponse[]> {
        return new Promise((resolve, reject) => {
            this.dataService.getPackages().then(packagesets => {
                return packagesets ? resolve(packagesets) : reject();
            });
        });
    }

    /**
     * Get Product by id
     *@param id type string
    */
    public getPackage(id: string): Promise<PackageResponse> {
        return new Promise((resolve, reject) => {
            this.dataService.getPackage(id).then(packageGet => {
                return packageGet ? resolve(packageGet) : reject();
            });
        });
    }

    /**
      * Add Package
      * @param package
      */
    public addPackage(packagesets: PackageSets): Promise<PackageSets> {
        return new Promise((resolve, reject) => {
            this.dataService.addPackage(PackageFactory.createAddRequest(packagesets)).then(response => {
                return packagesets ? resolve(packagesets) : reject();
            }).catch(reject);
        });
    }

    /**
    * Update Product
    * @param Product
    */
    public updatePackage(packageUpdated: PackageSets): Promise<PackageSets> {
        return new Promise((resolve, reject) => {
            this.dataService.updatePackage(packageUpdated.id, PackageFactory.createUpdateRequest(packageUpdated)).then(response => {
                return packageUpdated ? resolve(packageUpdated) : reject();
            }).catch(reject);
        });
    }

    /**
    * Delete Product
    * @param id
    */
    public deletePackage(id: string): Promise<PackageResponse> {
        return new Promise((resolve, reject) => {
            this.dataService.deletePackage(id).then(response => {
                if (response) { resolve(PackageFactory.delete(response)); }
            }).catch(reject);
        });
    }
}
