import { ScopeResponse } from '../services/scope/scope.response';
import { Scope } from '../models/scope';
import { AddScopeRequest } from '../services/scope/add.scope.request';
import { UpdateScopeRequest } from '../services/scope/update.scope.request';

export class ScopeFactory {
    /**
     * static create
     * @param model
     */
    public static create(model: ScopeResponse): Scope {
        return {
            id: model.id,
            name: model.name,
            description: model.description,
            packageSets:model.packageSets
        };
    }

    /**
     * createAddRequest
     * @param model
     */
    public static createAddRequest(model: Scope): AddScopeRequest {
        return {
            name: model.name,
            description: model.description,
            packageSets:model.packageSets
        };
    }

    /**
     *  createUpdateRequest
     * @param model
     */
    public static createUpdateRequest (model: Scope): UpdateScopeRequest {
        return {
            description: model.description,
            packageSets:model.packageSets
        };
    }
}
