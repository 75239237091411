import { MasterResponse } from '../services/master/master.response';
import { MasterMode } from '../models/master';
import { UpdateMasterRequest } from '../services/master/update.master.request';

export class MasterFactory {

    /**
     * create
     */
    public static create(model: MasterResponse): MasterMode {
        return{
           id: model.id,
           apiKey: model.apiKey,
           userName: model.userName,
           masterPassword: model.masterPassword
        };
    }

     /**
     * create
     */
    public static createUpdateRequest(model: MasterMode):  UpdateMasterRequest {
        return{
           id: model.id,
           packageManager: model.packageManager,
           apiKey: model.apiKey,
           userName: model.userName,
           masterPassword: model.masterPassword
        };
    }

}
