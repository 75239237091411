import { Scope } from './scope';

export class ProductDto {
    constructor(id?: string) {
        this.id = id;
        this.scopes = new Array<Scope>();
    }
    id: string;
    description: string;
    currency: string;
    price: number;
    scopes?: Array<Scope>;
    hidden:boolean;
}


