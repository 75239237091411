import { Component, OnInit,  Inject } from '@angular/core';
import { NgModel } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Link } from '../../models/links';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.css']
})
export class LinkComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: Link) { }

  dataSource = this.data;

  displayedColumns = ['Name', 'Uri', 'Description'];

  ngOnInit() {
  }
}
