import { Component, OnInit } from '@angular/core';
import { ProductDto } from '../../models/products';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from '../../services/product/product.service';
import { Link } from '../../models/links';
import { Scope } from '../../models/scope';
import { MatMenuModule } from '@angular/material/menu';
import { ScopeService } from '../../services/scope/scope.service';
import { ViewChild, ElementRef } from '@angular/core';



@Component({
  selector: 'app-product-add',
  templateUrl: './product-add.component.html',
  styleUrls: ['./product-add.component.css']
})
export class ProductAddComponent implements OnInit {

  @ViewChild('submitButton') submitButton: ElementRef;
  public product: ProductDto;
  public currencies: Array<string>;
  public listViewO: Array<CustomObject>;
  protected scope: Scope;
  public hidden: boolean = false;
  public scopeArr: Scope[] = [];

  constructor(
    protected productService: ProductService,
    protected scopeService: ScopeService,
    protected route: ActivatedRoute,
    protected router: Router,

  ) {
    this.currencies = new Array('EUR', 'USD');
    this.product = new ProductDto();
    this.product.currency = this.currencies[1];
    this.scope = new Scope();
  }

  ngOnInit() {
    this.listViewO = new Array<CustomObject>();

    this.scopeService.getScopes().then(data => {
      this.scopeArr = data;
      this.scopeArr.forEach(element => {
        const object = new CustomObject();
        object.ischecked = false;
        object.line = element;
        this.listViewO.push(object);
      });
    });
  }

  public click(i: number) {
    if (!this.listViewO[i].ischecked) {
      console.log(this.listViewO[i]);
    }
  }

  public clickHidden(i: boolean) {
    this.hidden = i;
  }

  public save() {

    this.submitButton.nativeElement.disabled = true;
    const res = this.listViewO.filter(item => item.ischecked);
    this.product.hidden = this.hidden;
    if (this.listViewO.length > 0) {
      this.listViewO.forEach(element => {
        if (element.ischecked) {
          this.product.scopes.push(element.line);
        }
      });
    }

    console.log(this.product);
    this.productService.addProduct(this.product).then((product) => {
      if (!product) {
        console.log('Fix issue with adding customer');
      }
      else {
        this.router.navigate(['products']);
      }
      this.submitButton.nativeElement.disabled = false;
    }).catch(err => {
      this.submitButton.nativeElement.disabled = false;
      console.log(err);
    });
    this.product = new ProductDto();
  }
}

export class CustomObject {
  ischecked: boolean;
  line: Scope;
}
