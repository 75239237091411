import { Injectable } from '@angular/core';
import { Scope } from '../../models/scope';
import { DataService } from '../data.service';
import { ScopeFactory } from '../../factories/scope.factory';

@Injectable()
export class ScopeService {

    constructor(private dataService: DataService) { }

    /**
     * Get all articles
     */
    public getScopes(): Promise<Scope[]> {
        return new Promise((resolve, reject) => {
            this.dataService.getScopes().then(cope => {
                return cope ? resolve(cope) : reject();
            });
        });
    }

    /**
     * Get Article by id
     *@param id type string
    */
    public getScope(id: string): Promise<Scope> {
       
         return new Promise((resolve, reject) => {
            this.dataService.getScope(id).then(scope => {
                return scope ? resolve(scope) : reject();
            });
        });
      
    }

    /**
     * Add Article
     * @param c
     */
    public addScope(scope: Scope): Promise<Scope> {
        return new Promise((resolve, reject) => {
            this.dataService.addScope(ScopeFactory.createAddRequest(scope)).then(response => {
                return scope ? resolve(scope) : reject();
            }).catch(reject);
        });
    }

    /**
    * Update article
    * @param scope
    */
    public updateScope(scope: Scope): Promise<Scope> {
        return new Promise((resolve, reject) => {
            this.dataService.updateScope(scope.id, ScopeFactory.createUpdateRequest(scope)).then(response => {
                return scope ? resolve(scope) : reject();
            }).catch(reject);
        });
    }

    /**
    * Delete Product
    * @param id
    */
    public deleteArticle(id: string): Promise<Scope> {
        return new Promise((resolve, reject) => {
            this.dataService.deleteScope(id).then(response => {
                if (response) { resolve(ScopeFactory.create(response)); }
            }).catch(reject);
        });
    }
}
